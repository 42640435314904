import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import PresetGrid from '../../components/personality_picker/PresetGrid';
import { color, motion } from 'framer-motion';
import AnimatedBackground from './AnimatedBackground';
import { Dialog } from 'primereact/dialog';
import Topbar from '../globals/Topbar';
import TriggerGrid from '../../components/triggers/TriggerGrid';
import Aliases from '../dialogs/aliases';
import IgnoredUsers from '../dialogs/ignoredusers';
import StreamStats from '../dialogs/streamstats';
import ChatLogs from '../dialogs/chatlogs';
import SliderDialog from '../dialogs/sliderdialog';
import TextInputDialog from '../dialogs/textinputdialog';
import { useUserSettings } from '../../store/usersettings-context';
import useLatestStreams from '../../utils/useLatestStreams';
import Spotify from '../../components/spotify';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateSpotifyRewardDialog from '../dialogs/createSpotifyReward';
import SpotifyBlockListDialog from '../dialogs/spotifyblocklist';
import useAIPresets from '../../utils/useAIPresets';
import Commands from '../../components/commands';
import NewTrigger from '../dialogs/newtrigger';
import NewCommand from '../dialogs/newCommand';
import Twitch from '../../components/twitch';
import PersonalityRewards from '../dialogs/personalityrewards';
import ReleaseNotes from '../dialogs/releasenotes';
import FAQ from '../dialogs/faq';
import useIsMobile from '../../utils/useIsMobile';
import BottomBar from '../globals/BottomBar';
import AddTwitchPermission from '../dialogs/addTwitchPermission';
import OBSTTS from '../dialogs/obstts';
import OBSTTSReward from '../dialogs/obsttsreward';
import useWindowSizeThreshold from '../../utils/useWindowsSizeThreshold';
import Admin from '../../components/admin';
import SubscriberCustomer from '../../components/admin/dialogs/SubscriberCustomer';
import SubscriberSubscription from '../../components/admin/dialogs/SubscriberSubscription';
import SubscriberInvoices from '../../components/admin/dialogs/SubscriberInvoices';
import CreatePerfumeIngredient from '../../components/admin/dialogs/CreatePerfumeIngredient';
import CreatePerfumeRecipe from '../../components/admin/dialogs/CreatePerfumeRecipe';
import ViewPerfumeRecipe from '../../components/admin/dialogs/ViewPerfumeRecipe';
import Editors from '../dialogs/editors';
import { useUser } from '../../context/UserContext';
import Cookies from 'js-cookie';
import EditCustomVariable from '../dialogs/editCustomVariable';
import Dashboard from '../../components/dashboard';
import NewMacro from '../dialogs/newMacro';
import SidebarMenu from '../globals/SidebarMenu';
import SystemCommands from '../../components/commands/SystemCommands';
import CommandGrid from '../../components/commands/CommandGrid';
import Macros from '../../components/commands/Macros';
import Variables from '../../components/commands/Variables';
import CustomVariables from '../../components/commands/CustomVariables';
import PersonalityBuilder from '../../components/personalitybuilder';
import useCustomAIPresets from '../../utils/useCustomAIPresets';
import InteractiveStory from '../../components/games/story';
import RedeemCode from '../dialogs/redeemCode';
import Keywords from '../../components/keywords';
import useShadeColor from '../../utils/shadeColor';
import useComplementaryTextColor from '../../utils/useComplementaryTextColor';
import './StandardView.css';
import SpotifyMain from '../../components/spotify/main';
import UpdateReward from '../dialogs/updateReward';
import SpotifyBlockedGenres from '../dialogs/spotifyBlockedGenres';
import TriviaSettings from '../../components/games/trivia';
import HangmanSettings from '../../components/games/hangman';
import StreamStatistics from '../../components/streamstats';
import Calendar from '../../components/calendar';

const StandardView = ({backgroundColor = '#5D3B9E', setBackgroundColor = () => {}}) => {
    const { userSettings, refresh, update, error, deletekey } = useUserSettings();
    const authCtx = useUser();
    const { filenames, loading } = useLatestStreams();
    const [sidebarHovered, setSidebarHovered] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const [mainViewHeader, setMainViewHeader] = useState('AI Presets');
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogHeader, setDialogHeader] = useState('');
    const [dialogComponent, setDialogComponent] = useState(<></>);
    const { presets, updatePresets } = useAIPresets();
    const { customPresets, updateCustomPresets } = useCustomAIPresets();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedTabParam = queryParams.get('p');
    const [mainViewComponent, setMainViewComponent] = useState(selectedTabParam || Cookies.get('mainViewComponent') || 'aipresets');
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(selectedTabParam || 'dashboard');
    const isMobile = useIsMobile();
    const isMedium = useWindowSizeThreshold(1280, true);
    // Get complementary color and update function
    const [complementaryColor, setComplementaryColor] = useComplementaryTextColor(backgroundColor);
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`,
        complementary25: `rgba(${parseInt(complementaryColor.slice(1, 3), 16)}, ${parseInt(complementaryColor.slice(3, 5), 16)}, ${parseInt(complementaryColor.slice(5, 7), 16)}, 0.25)`,
        complementary50: `rgba(${parseInt(complementaryColor.slice(1, 3), 16)}, ${parseInt(complementaryColor.slice(3, 5), 16)}, ${parseInt(complementaryColor.slice(5, 7), 16)}, 0.50)`,
        complementary75: `rgba(${parseInt(complementaryColor.slice(1, 3), 16)}, ${parseInt(complementaryColor.slice(3, 5), 16)}, ${parseInt(complementaryColor.slice(5, 7), 16)}, 0.75)`
    };

    // Use shaded complementary colors for styling
    const lighterColor = useShadeColor(complementaryColor, 30);
    const darkerColor = useShadeColor(complementaryColor, -30);
    const styles = {
        body: {
            '--background-25': colorScheme.background25,
            '--background-50': colorScheme.background50,
            '--background-75': colorScheme.background75,
            '--background-color': backgroundColor,
            '--background-color-lighter': useShadeColor(backgroundColor, 30),
            '--background-color-darker': useShadeColor(backgroundColor, -30),
            '--complementary-color': complementaryColor,
            '--complementary-color-lighter': useShadeColor(complementaryColor, 30),
            '--complementary-color-darker': useShadeColor(complementaryColor, -30),
            '--complementary-color-25': colorScheme.complementary25,
            '--complementary-color-50': colorScheme.complementary50,
            '--complementary-color-75': colorScheme.complementary75
        },
        title: {
            fontSize: '3rem',
            fontWeight: 700,
            color: '#FFFFFF',
            textShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
            letterSpacing: '0.1em',
            background: `-webkit-linear-gradient(45deg, ${lighterColor}, ${darkerColor})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            marginBottom: '1rem',
            userSelect: 'none'
        }
    };

    useEffect(() => {
        authCtx.checkIfSubscribed(authCtx.token);
        setSelectedTab(mainViewComponent);
        // Preserve existing parameters and set only 'p'
        const updatedParams = new URLSearchParams(location.search);
        updatedParams.set('p', mainViewComponent);
        navigate(`/settings?${updatedParams.toString()}`, { replace: true });
        if (authCtx.isSubscribed === false) navigate('/');
        Cookies.set('mainViewComponent', mainViewComponent);
        switch (mainViewComponent) {
            case 'aipresets':
                setMainViewHeader('AI Presets');
                updatePresets();
                updateCustomPresets();
                break;
            case 'dashboard':
                setMainViewHeader('Dashboard');
                break;
            case 'streamstats':
                setMainViewHeader('Statistics');
                break;
            case 'commands':
                setMainViewHeader('Commands');
                break;
            case 'systemcommands':
                setMainViewHeader('System Commands');
                break;
            case 'customcommands':
                setMainViewHeader('Custom Commands');
                break;
            case 'macros':
                setMainViewHeader('Macros');
                break;
            case 'systemvariables':
                setMainViewHeader('System Variables');
                break;
            case 'customvariables':
                setMainViewHeader('Custom Variables');
                break;
            case 'personalitybuilder':
                setMainViewHeader('Personality Builder');
                break;
            case 'triggers':
                setMainViewHeader('Triggers');
                break;
            case 'keywords':
                setMainViewHeader('Keywords');
                break;
            case 'calendar':
                setMainViewHeader('Calendar');
                break;
            case 'spotify':
                setMainViewHeader('Spotify');
                break;
            case 'twitch':
                setMainViewHeader('Twitch');
                break;
            case 'story':
                setMainViewHeader('Interactive Story');
                break;
            case 'trivia':
                setMainViewHeader('Trivia Game Settings');
                break;
            case 'hangman':
                setMainViewHeader('Hangman Game Settings');
                break;
            case 'admin':
                setMainViewHeader('Admin');
                break;
        }
    }, [mainViewComponent]);
    useEffect(() => {
        if (backgroundColor) {
            setComplementaryColor(backgroundColor);
            localStorage.setItem('backgroundColor', backgroundColor);
        }
    }, [backgroundColor, setComplementaryColor]);

    const openDialogComponent = (type, data = {}) => {
        setOpenDialog(true); 
        setIsBlur(true);
        switch (type) {
            case 'aliases':
                setDialogHeader('Aliases');
                setDialogComponent(<Aliases backgroundColor={colorScheme.background25} userSettings={userSettings} update={update} />);
                break;
            case 'ignoredusers':
                setDialogHeader('Ignored Users');
                setDialogComponent(<IgnoredUsers backgroundColor={colorScheme.background25} userSettings={userSettings} update={update} />);
                break;
            case 'streamstats':
                setDialogHeader('Stream Stats');
                setDialogComponent(<StreamStats backgroundColor={colorScheme.background25} filenames={filenames} loading={loading} defaultExpanded={data} />);
                break;
            case 'chatlogs':
                setDialogHeader('Chatlogs');
                setDialogComponent(<ChatLogs backgroundColor={colorScheme.background25} />);
                break;
            case 'buffertimer':
                setDialogHeader('Chat Buffer Timer');
                setDialogComponent(<SliderDialog footer='Set minimum time between bot messages in chat' value={parseInt(userSettings.messageInterval)/1000}
                    setValue={(v) => update({messageInterval: (v * 1000).toString()})} min={5} max={30} step={1} unit=' seconds' backgroundColor={backgroundColor} />);
                break;
            case 'usercontext':
                setDialogHeader('Context-Aware Tuning');
                setDialogComponent(<TextInputDialog footer='Provide additional information that Ratbot should be aware of' backgroundColor={backgroundColor}
                    value={userSettings.openAISettings.customPrompt} setValue={(v) => update({openAISettings: {...userSettings.openAISettings, customPrompt: v}})}
                    maxCharacters={1000} />);
                break;
            case 'exitmessage':
                setDialogHeader('Exit Message');
                setDialogComponent(<TextInputDialog footer='Ratbot will post this message in chat when your stream goes offline' backgroundColor={backgroundColor}
                    value={userSettings.exitMessage} setValue={(v) => update({exitMessage: v})} maxCharacters={500} />);
                break;
            case 'systemcommands':
                setDialogHeader('System Commands');
                setDialogComponent(<SystemCommands backgroundColor={colorScheme.background25} />)
                break;
            case 'spotifymintracklength':
                setDialogHeader('Minimum Track Length');
                setDialogComponent(<SliderDialog footer='Set minimum length for tracks requested'
                    value={userSettings.spotify.minLength} setValue={(v) => update({spotify: {...userSettings.spotify, minLength: v}})}
                    min={10} max={600} step={10} useTimeFormatting backgroundColor={backgroundColor} />);
                break;
            case 'spotifymaxtracklength':
                setDialogHeader('Maximum Track Length');
                setDialogComponent(<SliderDialog footer='Set maximum length for tracks requested'
                    value={userSettings.spotify.maxLength} setValue={(v) => update({spotify: {...userSettings.spotify, maxLength: v}})}
                    min={60} max={1800} step={30} useTimeFormatting backgroundColor={backgroundColor} />);
                break;
            case 'spotifyminpopularity':
                setDialogHeader('Minimum Popularity Threshold');
                setDialogComponent(<SliderDialog footer='Set minimum popularity rating for requested songs (0 = all songs)'
                    value={userSettings.spotify.minPopularity} setValue={(v) => update({spotify: {...userSettings.spotify, minPopularity: v}})}
                    min={0} max={100} step={1} backgroundColor={backgroundColor} unit='%' />);
                break;
            case 'createSpotifyReward':
                setDialogHeader('Channel Point Reward for Spotify');
                setDialogComponent(<CreateSpotifyRewardDialog footer='Create a Twitch reward for viewers to redeem song requests' />);
                break;
            case 'spotifyBlockedTracks':
                setDialogHeader('Blocked Tracks');
                setDialogComponent(<SpotifyBlockListDialog userSettings={userSettings} update={update} backgroundColor={backgroundColor} footer='Edit tracks to automatically block from being redeemed' blockList='blockedTracks' />);
                break;
            case 'spotifyBlockedArtists':
                setDialogHeader('Blocked Artists');
                setDialogComponent(<SpotifyBlockListDialog backgroundColor={backgroundColor} footer='Edit artists to automatically block from being redeemed' blockList='blockedArtists' />);
                break;
            case 'spotifyBlockedGenres':
                setDialogHeader('Blocked Genres');
                setDialogComponent(<SpotifyBlockedGenres backgroundColor={backgroundColor} footer='Choose which genres to allow and which to block' />)
                break;
            case 'spotifytrackconfirmationmsg':
                setDialogHeader('Spotify Track Add Message');
                setDialogComponent(<TextInputDialog footer='Message to post in chat when a track is added successfully to queue, leave empty for defaulted AI message'
                    value={userSettings['spotify']['trackAddConfirmationMsg'] ?? ''} maxCharacters={500}
                    setValue={(v) => update({spotify: {...userSettings['spotify'], trackAddConfirmationMsg: v}})}
                    placeholder='@${username} - ${requestedsong} was added successfully!' backgroundColor={backgroundColor} />)
                break;   
            case 'newtrigger':
                setDialogHeader(Object.keys(data).length === 0 ? 'New Trigger' : 'Edit Trigger');
                setDialogComponent(<NewTrigger userSettings={userSettings} update={update} backgroundColor={backgroundColor} setOpenDialog={setOpenDialog} setIsBlur={setIsBlur} editObj={Object.keys(data).length === 0 ? {} : data} />)
                break;
            case 'newcommand':
                setDialogHeader(Object.keys(data).length === 0 ? 'New Command' : 'Edit Command');
                setDialogComponent(<NewCommand backgroundColor={backgroundColor} setOpenDialog={setOpenDialog} setIsBlur={setIsBlur} editObj={Object.keys(data).length === 0 ? {} : data} />)
                break;
            case 'newmacro':
                setDialogHeader(Object.keys(data).length === 0 ? 'New Macro': 'Edit Macro');
                setDialogComponent(<NewMacro backgroundColor={backgroundColor} setOpenDialog={setOpenDialog} setIsBlur={setIsBlur} editObj={Object.keys(data).length === 0 ? {} : data} />)
                break;
            case 'twitchpolllength':
                setDialogHeader('Twitch Poll Length');
                setDialogComponent(<SliderDialog footer='Length of Twitch polls created by Ratbot' value={userSettings?.modData?.twitch?.commands?.create_poll?.duration ?? 60}
                    setValue={(v) => update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], create_poll: {...userSettings['modData']['twitch']['commands']['create_poll'], duration: v}}}}}, false)}
                    min={15} max={600} step={15} backgroundColor={backgroundColor} useTimeFormatting />);
                break;
            case 'twitchpollcommand':
                setDialogHeader('Twitch Poll Command');
                setDialogComponent(<TextInputDialog footer='Command to use for creating polls' backgroundColor={backgroundColor}
                    value={userSettings['modData']['twitch']['commands']['create_poll']['command'] ?? 'poll'} maxCharacters={20} prefix={userSettings['commandDelimiter']}
                    setValue={(v) => update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], create_poll: {...userSettings['modData']['twitch']['commands']['create_poll'], command: v}}}}})} />);
                break;
            case 'twitchpredictionlength':
                setDialogHeader('Twitch Prediction Window');
                setDialogComponent(<SliderDialog footer='The length of time that the prediction will run for' value={userSettings?.modData?.twitch?.commands?.create_prediction?.prediction_window ?? 300}
                    setValue={(v) => update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], create_prediction: {...userSettings['modData']['twitch']['commands']['create_prediction'], prediction_window: v}}}}}, false)}
                    min={30} max={1800} step={30} backgroundColor={backgroundColor} useTimeFormatting />);
                break;
            case 'twitchpredictioncommand':
                setDialogHeader('Twitch Prediction Command');
                setDialogComponent(<TextInputDialog footer='Command to use for creating predictions' backgroundColor={backgroundColor}
                    value={userSettings['modData']['twitch']['commands']['create_prediction']['command'] ?? 'predict'} maxCharacters={20} prefix={userSettings['commandDelimiter']}
                    setValue={(v) => update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], create_prediction: {...userSettings['modData']['twitch']['commands']['create_prediction'], command: v}}}}})} />);
                break;
            case 'twitchendpredictioncommand':
                setDialogHeader('Twitch End Prediction Command');
                setDialogComponent(<TextInputDialog footer='Command to use for ending ongoing predictions' backgroundColor={backgroundColor}
                    value={userSettings['modData']['twitch']['commands']['end_prediction']['command'] ?? 'endpredict'} maxCharacters={20} prefix={userSettings['commandDelimiter']}
                    setValue={(v) => update({modData: {...userSettings['modData'], twitch: {...userSettings['modData']['twitch'], commands: {...userSettings['modData']['twitch']['commands'], end_prediction: {...userSettings['modData']['twitch']['commands']['end_prediction'], command: v}}}}})} />);
                break;
            case 'twitchpersonalityrewards':
                setDialogHeader('Viewer Rewards: Personalities');
                setDialogComponent(<PersonalityRewards footer='Creater viewer rewards for changing Ratbot personality' backgroundColor={backgroundColor}
                    presets={presets} userSettings={userSettings} update={update} deletekey={deletekey} refresh={refresh} />);
                break;
            case 'updatereward':
                setDialogHeader('Update Channel Points Reward');
                setDialogComponent(<UpdateReward backgroundColor={backgroundColor} footer='Updates a channel point reward on Twitch' rewardObj={Object.keys(data).length > 0 ? data : {}} />);
                break;
            case 'releasenotes':
                setDialogHeader('Release Notes');
                setDialogComponent(<ReleaseNotes footer='Update log for Ratbot and the ratbot.store website' />);
                break;
            case 'faq':
                setDialogHeader('FAQ');
                setDialogComponent(<FAQ footer='Frequently Asked Questions' />);
                break;
            case 'addtwitchpermission':
                setDialogHeader('Add Twitch Permission');
                setDialogComponent(<AddTwitchPermission footer='Add additional Twitch permissions for Ratbot' backgroundColor={backgroundColor}
                    scope={data['scope']} />)
                break;
            case 'obstts':
                setDialogHeader('RatbotTTS (Text-to-Speech)');
                setDialogComponent(<OBSTTS footer='Set up the text-to-speech parameters for the RatbotTTS OBS plugin' backgroundColor={backgroundColor}
                    userSettings={userSettings} update={update} setOpenDialog={setOpenDialog} setIsBlur={setIsBlur} />);
                break;
            case 'obsttsreward':
                setDialogHeader('Channel Points Reward for RatbotTTS');
                setDialogComponent(<OBSTTSReward footer='Creater viewer rewards for changing Ratbot personality' backgroundColor={backgroundColor}
                    presets={presets} userSettings={userSettings} update={update} deletekey={deletekey} refresh={refresh} />);
                break;
            case 'emotefrequency':
                setDialogHeader('Emote Use Frequency');
                setDialogComponent(<SliderDialog footer='Adjust how often Ratbot adds a 7TV/BTTV emote at the end of chat messages' 
                    value={userSettings?.emoteFrequency ?? 50} setValue={(v) => update({emoteFrequency: v})}
                min={0} max={100} step={5} backgroundColor={backgroundColor} />);
                break;
            case 'editors':
                setDialogHeader('Editors');
                setDialogComponent(<Editors backgroundColor={backgroundColor} editorsList={userSettings['editors'] ? userSettings['editors'] : []} 
                    updateEditors={(editors) => update({editors})} />);
                break;
            case 'editcustomvariable':
                setDialogHeader('Edit Custom Variable');
                setDialogComponent(<EditCustomVariable backgroundColor={backgroundColor} userSettings={userSettings} update={update} code={data.code}
                                        setOpenDialog={setOpenDialog} setIsBlur={setIsBlur} deletekey={deletekey} />);
                break;
            case 'redeemcode':
                setDialogHeader('Redeem Voucher Code');
                setDialogComponent(<RedeemCode backgroundColor={backgroundColor} footer='Redeem a code provided to you' value='' prefix='' setOpenDialog={setOpenDialog} />)
                break;
            case 'subscribercustomer':
                setDialogHeader(`Customer: ${data['customer']['username']}`);
                setDialogComponent(<SubscriberCustomer footer='Stripe customer data' backgroundColor={backgroundColor}
                    customerObj={data['customer']} />);
                break;
            case 'subscribersubscription':
                setDialogHeader(`Subscription: ${data['customer']['username']}`);
                setDialogComponent(<SubscriberSubscription footer='Stripe subscription data' backgroundColor={backgroundColor}
                    customerObj={data['customer']} />);
                break;
            case 'subscriberinvoices':
                setDialogHeader(`Invoices for: ${data['customer']['username']}`);
                setDialogComponent(<SubscriberInvoices footer='Strip invoices' backgroundColor={backgroundColor}
                    customerObj={data['customer']} />);
                break;
            case 'createperfumeingredient':
                setDialogHeader('Create Perfume Ingredient');
                setDialogComponent(<CreatePerfumeIngredient backgroundColor={backgroundColor} footer='Create ingredients to use in perfume recipes' 
                    setOpenDialog={setOpenDialog} setIsBlur={setIsBlur} />);
                break;
            case 'createperfumerecipe':
                setDialogHeader('Create Perfume Recipe');
                setDialogComponent(<CreatePerfumeRecipe backgroundColor={backgroundColor} footer='Create perfume recipe' 
                    setOpenDialog={setOpenDialog} setIsBlur={setIsBlur} ingredients={data} />);
                break;
            case 'viewperfumerecipe':
                setDialogHeader(data['title']);
                setDialogComponent(<ViewPerfumeRecipe backgroundColor={backgroundColor} footer={data['description']} setOpenDialog={setOpenDialog}
                    setIsBlur={setIsBlur} recipe={data} />);
                break;

            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <div style={styles.body}>
                <Dialog header={dialogHeader} visible={openDialog} onHide={() => {setOpenDialog(false); setIsBlur(false);}} resizable={false} style={{...styles.body}}
                    headerStyle={{backgroundColor: colorScheme.background50}} draggable={false} contentStyle={{backgroundColor: colorScheme.background50}}>
                    {dialogComponent}
                </Dialog>
                {Object.keys(userSettings).length > 1 && (<div style={{position: 'absolute', top: 0, left: 0, height: '100vh', width: '100vw', filter: isBlur && 'blur(10px) brightness(0.75)', transition: 'filter 0.5s'}}>
                    <Topbar setIsBlur={setIsBlur} openDialogComponent={openDialogComponent} setMainViewComponent={setMainViewComponent} setMainViewHeader={setMainViewHeader}
                        userSettings={userSettings} update={update} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} />
                    {!isMobile ? (
                        <div>
                        {/*<Sidebar setSidebarHovered={setSidebarHovered} mainViewComponent={mainViewComponent} setMainViewComponent={setMainViewComponent} 
                            mainViewHeader={mainViewHeader} setMainViewHeader={setMainViewHeader} />*/}
                            <SidebarMenu backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} setMainViewComponent={setMainViewComponent}/>
                        </div>
                    ) : (
                        <BottomBar backgroundColor={backgroundColor} selectedTextColor='#e0e0e0' openDialogComponent={openDialogComponent}
                            setMainViewComponent={setMainViewComponent} />
                    )}
                    <motion.div animate={!isMobile ? {marginLeft: sidebarHovered ? '8vw' : '200px'} : {marginLeft: '5vw'}} 
                        transition={!isMobile && { duration: 0.25, type: 'spring', stiffness: 100 }} style={{height: '100%'}}>
                        <Box sx={{width: '100%', height: '100%', marginTop: isMobile && '12.5vh', 
                            paddingLeft: (isMobile && isMedium) ? '0px' : (!isMobile && isMedium) ? '32px' : '24px'}}>
                            {!isMobile && (<Typography variant='h1' sx={{ ...styles.title, marginTop: '9.5vh' }}>{mainViewHeader}</Typography>)}
                            {mainViewComponent === 'aipresets' && <PresetGrid filter={[]} presets={presets} updatePresets={updatePresets} userSettings={userSettings} update={update} 
                                                                    customPresets={customPresets} updateCustomPresets={updateCustomPresets} backgroundColor={backgroundColor} />}
                            {mainViewComponent === 'personalitybuilder' && (<PersonalityBuilder backgroundColor={backgroundColor} />)}
                            {mainViewComponent === 'dashboard' && (<Dashboard openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} 
                                                                    update={update} refresh={refresh} backgroundColor={backgroundColor} />)}
                            {mainViewComponent === 'streamstats' && (<StreamStatistics openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} 
                                                                    update={update} refresh={refresh} backgroundColor={backgroundColor} />)}
                            {mainViewComponent === 'commands' && (<Commands openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} backgroundColor={backgroundColor}
                                                                    userSettings={userSettings} update={update} deletekey={deletekey} />)}
                            {mainViewComponent === 'systemcommands' && (<SystemCommands backgroundColor={backgroundColor} userSettings={userSettings} update={update} />)}
                            {mainViewComponent === 'customcommands' && (<CommandGrid backgroundColor={backgroundColor} openDialogComponent={openDialogComponent}
                                                                            userSettings={userSettings} update={update} deletekey={deletekey} />)}
                            {mainViewComponent === 'triggers' && (<TriggerGrid openDialogComponent={openDialogComponent} userSettings={userSettings} update={update}
                                                                    backgroundColor={backgroundColor} />)}
                            {mainViewComponent === 'macros' && (<Macros backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} userSettings={userSettings}
                                                                    update={update} deletekey={deletekey} />)}
                            {mainViewComponent === 'keywords' && <Keywords backgroundColor={backgroundColor} openDialogComponent={openDialogComponent} userSettings={userSettings}
                                                                    update={update} deletekey={deletekey} />}
                            {mainViewComponent === 'calendar' && <Calendar openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} 
                                                                    update={update} refresh={refresh} backgroundColor={backgroundColor} />}
                            {mainViewComponent === 'systemvariables' && (<Variables backgroundColor={backgroundColor} />)}
                            {mainViewComponent === 'customvariables' && (<CustomVariables backgroundColor={backgroundColor} userSettings={userSettings} update={update}
                                                                            openDialogComponent={openDialogComponent} deletekey={deletekey} />)}
                            {mainViewComponent === 'spotify' && (<SpotifyMain openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} 
                                                                    update={update} refresh={refresh} backgroundColor={backgroundColor} />)}
                            {mainViewComponent === 'twitch' && (<Twitch openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} 
                                                                    update={update} refresh={refresh} backgroundColor={backgroundColor} />)}
                            {mainViewComponent === 'story' && (<InteractiveStory openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} 
                                                                    update={update} refresh={refresh} setMainViewHeader={setMainViewHeader} backgroundColor={backgroundColor} />)}
                            {mainViewComponent === 'trivia' && (<TriviaSettings openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings}
                                                                    update={update} refresh={refresh} setMainViewHeader={setMainViewHeader} backgroundColor={backgroundColor} />)}
                            {mainViewComponent === 'hangman' && (<HangmanSettings openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings}
                                                                    update={update} refresh={refresh} setMainViewHeader={setMainViewHeader} backgroundColor={backgroundColor} />)}
                            {mainViewComponent === 'admin' && (<Admin openDialogComponent={openDialogComponent} setIsBlur={setIsBlur} userSettings={userSettings} 
                                                                    update={update} refresh={refresh} backgroundColor={backgroundColor} />)}
                        </Box>
                    </motion.div>
                </div>)}
            </div>
        </React.Fragment>
    );
};

const StandardViewWithBackground = () => {
    const [backgroundColor, setBackgroundColor] = useState(localStorage.getItem('backgroundColor') || '#412c69'); // Default color
    const [animateBackground, setAnimateBackground] = useState(localStorage.getItem('doAnimateBackground') === 'true');

    // Load the saved background color from localStorage on component mount
    useEffect(() => {
        const savedColor = localStorage.getItem('backgroundColor');
        const doAnimateBackground = localStorage.getItem('doAnimateBackground');
        if (!doAnimateBackground) {
            setAnimateBackground(true);
        }
        if (savedColor) {
            setBackgroundColor(savedColor);
        }
    }, []);

    const bgImages = [
        'https://i.imgur.com/bFtXJAt.png',
        'https://i.imgur.com/BPQZiYY.png',
        'https://i.imgur.com/HVixLJS.png',
        'https://i.imgur.com/tdk8lox.png',
        'https://i.imgur.com/4Yx6KlM.png',
        'https://i.imgur.com/wDCx2cm.png',
        'https://i.imgur.com/W6xR5We.png',
        'https://i.imgur.com/VyOlaS2.png',
        'https://i.imgur.com/me96rQb.png',
        'https://i.imgur.com/U6JAaMO.png',
    ];
    const getRandomUniqueElements = (arr) => {
        for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        return arr.slice(0, 2);
    }
    const selectedImages = getRandomUniqueElements(bgImages);

    return (
        <React.Fragment>
            {animateBackground ? (
                <AnimatedBackground selectedImages={selectedImages} tintColor={backgroundColor}>
                    <StandardView backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} />
                </AnimatedBackground>
            ) : (
                <StandardView backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} />
            )}
        </React.Fragment>
    );
};

export default React.memo(StandardViewWithBackground);