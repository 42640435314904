import React, { useState } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { FaHome, FaAndroid, FaTerminal, FaBolt, FaSlidersH, FaThLarge, FaSitemap, FaRobot, FaCog, FaPencilAlt, FaWrench, FaMusic, FaVideo, FaGamepad, FaBook, FaFont, FaBars, FaChartLine, FaCalendar } from 'react-icons/fa';
import 'react-pro-sidebar/dist/css/styles.css';
import './SidebarMenu.css';

const SidebarMenu = ({ backgroundColor = '#5D3B9E', setIsBlur = () => {}, openDialogComponent = () => {}, setMainViewComponent = () => {}, setMainViewHeader = () => {} }) => {
    const [openSubMenu, setOpenSubMenu] = useState(null);

    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };

    const handleSubMenuClick = (menuName) => {
        setOpenSubMenu(openSubMenu === menuName ? null : menuName);
    };

    const handleMenuItemClick = (event, componentName) => {
        event.stopPropagation();  // Prevents the SubMenu's onClick from firing
        setMainViewComponent(componentName);
    };

    const MenuLabel = ({level, children}) => {
        const Tag = level === 'sub' ? 'h5' : 'h4'; // Use h5 for subitems, h4 for top-level items
        return (
            <Tag style={{ margin: '0px', padding: '0px' }}>
                {children}
            </Tag>
        );
    };

    return (
        <ProSidebar breakPoint="sm" className="custom-sidebar" width="200px" style={{'--icon-background-color': colorScheme.background75}}>
            <Menu iconShape="circle" style={{ backgroundColor: colorScheme.background25, height: '100vh', borderRight: `2px solid ${colorScheme.background50}` }}>
                <MenuItem onClick={() => setMainViewComponent('dashboard')} icon={<FaHome />}><MenuLabel level='h4'>Dashboard</MenuLabel></MenuItem>
                <MenuItem onClick={() => setMainViewComponent('streamstats')} icon={<FaChartLine />}><MenuLabel level='h4'>Statistics</MenuLabel></MenuItem>
                <SubMenu title={<MenuLabel level='h4'>Personalities</MenuLabel>} icon={<FaAndroid />}
                         open={openSubMenu === 'personalities'} onClick={() => handleSubMenuClick('personalities')}>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'aipresets')} icon={<FaRobot />}><MenuLabel level='h4'>My Presets</MenuLabel></MenuItem>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'personalitybuilder')} icon={<FaWrench />}><MenuLabel level='h4'>Create New</MenuLabel></MenuItem>
                </SubMenu>
                <SubMenu title={<MenuLabel level='h4'>Commands</MenuLabel>} icon={<FaTerminal />}
                         open={openSubMenu === 'commands'} onClick={() => handleSubMenuClick('commands')}>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'systemcommands')} icon={<FaCog />}><MenuLabel level='h4'>Built-in</MenuLabel></MenuItem>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'customcommands')} icon={<FaPencilAlt />}><MenuLabel level='h4'>My Commands</MenuLabel></MenuItem>
                </SubMenu>
                <MenuItem onClick={() => setMainViewComponent('triggers')} icon={<FaBolt />}><MenuLabel level='h4'>Triggers</MenuLabel></MenuItem>
                <MenuItem onClick={() => setMainViewComponent('macros')} icon={<FaSlidersH />}><MenuLabel level='h4'>Macros</MenuLabel></MenuItem>
                <MenuItem onClick={() => setMainViewComponent('keywords')} icon={<FaFont />}><MenuLabel level='h4'>Keywords</MenuLabel></MenuItem>
                <MenuItem onClick={() => setMainViewComponent('calendar')} icon={<FaCalendar />}><MenuLabel level='h4'>Calendar</MenuLabel></MenuItem>
                <SubMenu title={<MenuLabel level='h4'>Variables</MenuLabel>} icon={<FaThLarge />}
                         open={openSubMenu === 'variables'} onClick={() => handleSubMenuClick('variables')}>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'systemvariables')} icon={<FaCog />}><MenuLabel level='h4'>System</MenuLabel></MenuItem>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'customvariables')} icon={<FaPencilAlt />}><MenuLabel level='h4'>My Variables</MenuLabel></MenuItem>
                </SubMenu>
                <SubMenu title={<MenuLabel level='h4'>Integrations</MenuLabel>} icon={<FaSitemap />}
                         open={openSubMenu === 'integrations'} onClick={() => handleSubMenuClick('integrations')}>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'spotify')} icon={<FaMusic />}><MenuLabel level='h4'>Spotify</MenuLabel></MenuItem>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'twitch')} icon={<FaVideo />}><MenuLabel level='h4'>Twitch</MenuLabel></MenuItem>
                </SubMenu>
                <SubMenu title={<MenuLabel level='h4'>Games</MenuLabel>} icon={<FaGamepad />}
                         open={openSubMenu === 'games'} onClick={() => handleSubMenuClick('games')}>
                    {/*<MenuItem onClick={(e) => handleMenuItemClick(e, 'story')} icon={<FaBook />}><MenuLabel level='h4'>Story</MenuLabel></MenuItem>*/}
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'trivia')} icon={<FaBook />}><MenuLabel level='h4'>Trivia</MenuLabel></MenuItem>
                    <MenuItem onClick={(e) => handleMenuItemClick(e, 'hangman')} icon={<FaBook />}><MenuLabel level='h4'>Hangman</MenuLabel></MenuItem>
                </SubMenu>
            </Menu>
        </ProSidebar>
    );
};

export default SidebarMenu;