/**
 * Represents a calendar event.
 */
class Event {
    /**
     * Creates a new Event instance.
     * @param {string} id - Unique identifier for the event.
     * @param {string} title - Title or name of the event.
     * @param {Date|string} start - Start date and time of the event (UTC format preferred).
     * @param {Date|string} end - End date and time of the event (UTC format preferred).
     * @param {boolean} allDay - Indicates if the event spans the entire day.
     * @param {string} type - The type of the event. Possible values: 'reminder', 'context', 'private'.
     * @param {boolean} [notified=false] - Flag indicating if the user has been notified (only for reminders).
     * @param {string} [contextText=""] - Additional context text (only for context events).
     * @param {string} [description=""] - Optional description or details about the event.
     */
    constructor(id, title, start, end, allDay = false, type, notified = false, contextText = '', description = '') {
        this.id = id; // Event ID (e.g., UUID or unique string)
        this.title = title; // Event title
        this.start = new Date(start); // Start time of the event
        this.end = new Date(end); // End time of the event
        this.allDay = allDay; // Is the event an all-day event?
        this.type = type; // Type of event: reminder, context, or private

        // Type-specific properties
        if (type === 'reminder') {
            this.notified = notified; // Has the user been notified for the reminder?
        } else if (type === 'context') {
            this.contextText = contextText; // Additional context for the event
        }

        this.description = description; // Optional event description
    };

    /**
     * Checks if the event is ongoing at the given date and time.
     * @param {Date} date - The date to check.
     * @returns {boolean} True if the event is ongoing, false otherwise.
     */
    isOngoing(date) {
        const checkDate = new Date(date);
        return checkDate >= this.start && checkDate <= this.end;
    };
    /**
     * Marks a reminder event as notified.
     * Throws an error if the event type is not 'reminder'.
     */
    markAsNotified() {
        if (this.type !== 'reminder') {
        throw new Error("Only 'reminder' events can be marked as notified.");
        }
        this.notified = true;
    };
    /**
     * Returns a formatted string representation of the event.
     * Includes type-specific fields.
     * @returns {string} The event as a string.
     */
    toString() {
        let baseString = `${this.title} (from ${this.start.toLocaleString()} to ${this.end.toLocaleString()})`;
        if (this.type === 'reminder') {
        baseString += ` - Notified: ${this.notified}`;
        } else if (this.type === 'context') {
        baseString += ` - Context: ${this.contextText}`;
        } else if (this.type === 'private') {
        baseString += ` - Private event`;
        }
        return baseString;
    };
};

export default Event;  