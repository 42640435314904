import React, { useState } from 'react';
import MainView from '../views/MainView';
import FullCalendar from "@fullcalendar/react"; // https://fullcalendar.io/docs
import { formatDate } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { Box, Grid } from '@mui/material';
import Event from './Event';
import { Calendar as PrimeCalendar } from 'primereact/calendar';

const Calendar = ({ openDialogComponent = () => {}, setIsBlur = () => {}, backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, refresh = () => {} }) => {
    const [tzOffset, setTzOffset] = useState(null);
    const [events, setEvents] = useState([
        new Event('test1', 'Event 1', '2025-02-13T23:00:00.000Z', '2025-02-14T23:00:00.000Z', true, 'private', false, '', '')
    ]);
    const [calendarSelection, setCalendarSelection] = useState(null);
    const [eventSelection, setEventSelection] = useState(null);
    const [newEvent, setNewEvent] = useState({
        id: '',
        title: '',
        start: null,
        end: null,
        allDay: true,
        type: 'private',
        notified: false,
        contextText: ''
    });
    const [dateRange, setDateRange] = useState(null);
    const styles = {
        headerText: {
            marginTop: '0px',
            color: 'var(--complementary-color)'
        }
    };

    const handleDateClick = (selected) => {
        setCalendarSelection(selected);
        setEventSelection(null);
        setDateRange([selected.start, selected.end]);
    };
    const handleEventClick = (selected) => {
        setEventSelection(selected);
    };
    const handleUnselect = () => {
        //setCalendarSelection(null);
        //setEventSelection(null);
    };

    return (
        <MainView>
            <Grid container spacing={4}>
                <Grid item xs={8}>
                    <FullCalendar plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]} initialView='dayGridMonth' height='600px'
                        headerToolbar={{left: 'prev,next today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'}}
                        editable={true} selectable={true} selectMirror={true} dayMaxEvents={true} select={handleDateClick} unselect={handleUnselect}
                        initialEvents={events} eventClick={handleEventClick} />
                </Grid>
                <Grid item xs={4}>
                    {eventSelection ? (
                        <p>{JSON.stringify(eventSelection, null, 4)}</p>
                    ) : calendarSelection ? (
                        <React.Fragment>
                            <h2>Create New Event</h2>
                            {calendarSelection.allDay === true ? (
                                <React.Fragment>
                                    <PrimeCalendar value={dateRange} onChange={(e) => setDateRange(e.value)} selectionMode='range' readOnlyInput
                                        hideOnRangeSelection />
                                    <p>{JSON.stringify(dateRange, null, 4)}</p>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <p>Nothing Selected</p>
                    )}
                </Grid>
            </Grid>
        </MainView>
    );
};

export default Calendar;